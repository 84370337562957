import "core-js/modules/es.array.push.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "wrap"
  }, [_c('div', {
    staticClass: "index-div"
  }, [_c('div', {
    staticClass: "info-box"
  }, [_c('img', {
    staticClass: "icon",
    attrs: {
      "src": require("../../assets/integral/icon-3.png")
    }
  }), _c('div', {
    staticClass: "integral-label"
  }, [_vm._v("金币")]), _c('div', {
    staticClass: "integral-value"
  }, [_vm._v(_vm._s(_vm.$store.state.integralCurrency.gold))]), _c('div', {
    staticClass: "user",
    on: {
      "click": function ($event) {
        return _vm.$router.push('/integral/user');
      }
    }
  }), _c('div', {
    staticClass: "button",
    on: {
      "click": function ($event) {
        return _vm.$router.push('/integral/apply');
      }
    }
  }, [_vm._v("提取")])]), _c('div', {
    staticClass: "record-box"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("金币记录")]), _c('parent-list-content', {
    ref: "parentListContent",
    on: {
      "onDataLoad": _vm.onDataLoad,
      "pushData": _vm.pushData
    }
  }, [_c('div', {
    staticClass: "record-list"
  }, _vm._l(_vm.itemList, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "li"
    }, [_c('div', {
      staticClass: "time"
    }, [_vm._v(_vm._s(item.createTime))]), _c('div', {
      staticClass: "type"
    }, [_vm._v(_vm._s(item.remark))]), _c('div', {
      staticClass: "value",
      class: {
        'value-j': item.type == 1
      }
    }, [_vm._v(_vm._s(item.type == 1 ? '-' : '+') + " " + _vm._s(item.count))])]);
  }), 0)])], 1)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };