export default {
  name: 'Index',
  components: {},
  data() {
    return {
      itemList: []
    };
  },
  async created() {},
  async mounted() {},
  methods: {
    onDataLoad: async function (options, callback) {
      const result = await this.$request({
        url: '/app/api/my/gold/record',
        method: 'get',
        params: options
      });
      callback(result);
    },
    pushData(items, type) {
      console.log(items);
      console.log(type);
      if (type === 'set') this.itemList = items;
      if (type === 'add') this.itemList = this.itemList.concat(items);
      // this.itemList = items
    }
  }
};